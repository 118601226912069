import React, { Component } from 'react'

import axios from 'axios'

import GooglePlannerMap from '../../components/map/map'
import Search from '../../components/map/search-location'
import DaysList from '../../components/trip/DaysList'
import TripCardList from '../../components/trip/TripDetailsCard'
import theme from '../../utils/theme'
import Layout from '../../components/layout'

import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { Grid, Container, Button, TextField, InputLabel, Select, MenuItem, FormControl } from "@material-ui/core"


// import './styles/create-trip.scss'

const styles = theme => ({
    componentWrapper: {
        [theme.breakpoints.only('sm')]: {
            padding: `${theme.spacing(4)}px  ${theme.spacing(3)}px`,
        },
        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing(4)}px  ${theme.spacing(2)}px}`,
        },
        padding: `${theme.spacing(4)}px ${theme.spacing(8)}px`,
        '&:nth-child(1)': {
            paddingTop: theme.spacing(3),
        },
        height: "100%"
    },
    mainComponent: {
        // height: "100%",
    },
    leftMapping: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: "grey",
            position: "fixed",
            bottom: '0px',
            width: "100%",
            top: "auto",
            left: "0px",
            zIndex: "100",
            height: '0'
        },
        position: '-webkit - sticky',
        position: 'sticky',
        top: '20px',
        height: 'fit-content',
        transition: 'height 0.2s ease',
    },
    divSticky: {
        position: '-webkit - sticky',
        position: 'sticky',
        top: '20px',
        height: 'fit-content',
    },
    mapShow: {
        [theme.breakpoints.down('sm')]: {
            height: '88%',
        }
    },
    mapShowIcon: {
        [theme.breakpoints.up('md')]: {
            display: "none"
        },
        position: 'absolute',
        left: '48%',
        top: '-16px',
        fontSize: '32px',
        backgroundColor: theme.palette.primary.light,
        borderRadius: '50%',
        zIndex: '101',

    },
    hidden: {
        display: "none"
    },
    rightMapping: {
        position: "relative",
    },
    addingPlace: {
        position: "absolute",
        top: 0,
        backgroundColor: "white",
        zIndex: "10"
    },
    formControl: {
        [theme.breakpoints.only('sm')]: {
            minWidth: '100px',
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '40px',
        },
        margin: theme.spacing(1),
        minWidth: 120,
    },
    addPlace: {
        backgroundColor: 'white',
        zIndex: '10',
        top: '0px',
    },
    rotate180: {
        transform: "rotate(180deg)"
    }
})

class CreateTrip extends Component {
    constructor() {
        super();
        this.state = {
            "markers": [],
            "daySelected": "",
            "selectedMarker": {},
            "authenticated": true,
            "mapLoaded": false,
            "addingPlace": false,
            "showMap": false
        }
    }

    handleMapLoaded = () => {
        this.setState({ "mapLoaded": true })
    }

    fetchPhoto = (placeId) => {
        let params = {
            placeId: placeId,
            fields: ['photos']
        };
        let service = new google.maps.places.PlacesService(document.createElement('div'));
        return new Promise((resolve, reject) => {
            service.getDetails(params, (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    resolve(place.photos[0].getUrl())
                } else {
                    reject("not")
                }
            });
        })
    }

    //update placename and desc
    updateInput = (data, index, inputType) => {
        console.log(data, index)
        if (inputType === "place") {
            this.fetchPhoto(data.place_id).then((resp) => {
                data["photo_url"] = resp
                this.setState(prevState => ({ ...prevState, markers: [...prevState.markers.slice(0, index), data, ...prevState.markers.slice(index + 1)], selectedMarker: { ...data } }))
            }).catch(err => console.log(err))
        } else {
            this.setState(prevState => ({ ...prevState, markers: [...prevState.markers.slice(0, index), data, ...prevState.markers.slice(index + 1)] }))
        }
    }

    addPlace = () => {
        let newMarker =
        {
            id: Date.now(),
            lat: "",
            lng: "",
            photo_url: "",
            placeName: "",
            place_id: ""
        }
        this.setState(prevState => ({ ...prevState, markers: [...prevState.markers, newMarker] }))
    }

    addingPlace = (state) => {
        this.setState({ addingPlace: state })
    }

    //add marker from suggestions
    // addMarker = (day) => {
    //     this.fetchPhoto(this.state.selectedMarker.place_id).then((resp) => {
    //         this.setState(prevState => ({ ...prevState, addingPlace: false, markers: { ...prevState.markers, [day]: [...prevState.markers[day], { ...prevState.selectedMarker, photo_url: resp }] } }))
    //     }).catch(err => console.log(err))
    // }

    //delete day on delete button click
    deleteDay = (day) => {
        console.log(day)
    }

    deleteMarker = (index) => {
        this.setState(prevState => ({ ...prevState, markers: [...prevState.markers.slice(0, index), ...prevState.markers.slice(index + 1)] }))
    }

    //selected which day to add the selected marker
    selectDay = (day) => {
        this.setState({ daySelected: day })
    }

    // selectSearchOption = (data, inputType) => {
    //     this.setState(prevState => ({ selectedMarker: data }))
    // }

    toggleMap = () => {
        this.setState(prevState => ({ showMap: !prevState.showMap }))
    }

    saveTrip = () => {
        axios.post('https://crm-api-flockalong.azurewebsites.net/api/Logger?code=I1NiLBPKPUruq3tUTyLb1gt8WelY8rRN9jILbpfrQdUSia3e3WMaUg==', this.state.markers).then(res => { console.log("Done!", res); alert("Trip saved") })
    }

    componentDidUpdate() {
        //update localStorage when component did update
        window.localStorage.setItem("state", JSON.stringify(this.state))

    }

    componentDidMount() {
        //get state from local storage
        this.setState(JSON.parse(window.localStorage.getItem("state")))
        //set daySelected and selectedMarker to null
        this.setState(prevState => ({ ...prevState, daySelected: null, selectedMarker: {}, mapLoaded: false, addingPlace: false, showMap: false }))
    }

    render() {
        const { classes } = this.props
        return (
            <ThemeProvider theme={theme}>
                <Layout>
                    <Container className={classes.componentWrapper}>
                        <Grid container spacing={2} className={classes.mainComponent}>
                            <Grid item xs={12} md={3} className={this.state.showMap ? `${classes.leftMapping}  ${classes.mapShow}` : classes.leftMapping}>
                                <ExpandLessIcon className={this.state.showMap ? `${classes.mapShowIcon} ${classes.rotate180}` : classes.mapShowIcon} onClick={this.toggleMap} />
                                <Grid container item spacing={1}>
                                    <Grid item xs={12}>
                                        <GooglePlannerMap parentMarkers={this.state.markers} selectedMarker={this.state.selectedMarker} mapLoaded={this.handleMapLoaded} dimensions={{ width: "100%", height: "250px" }} ></GooglePlannerMap>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DaysList markers={this.state.markers} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={9} className={classes.rightMapping} spacing={1}>
                                <Grid item xs={12}>
                                    <TripCardList markers={this.state.markers} updateInput={this.updateInput} mapLoaded={this.state.mapLoaded} deleteMarker={this.deleteMarker} />
                                </Grid>

                                <Grid container item xs={12} className={`${classes.addPlace}  ${classes.divSticky}`} direction="row" spacing={1} justify="space-between">
                                </Grid>
                                <Grid item xs={12}>
                                    <Button fullWidth color="primary" variant="outlined" onClick={() => this.addPlace(!this.state.addingPlace)}>Add Place</Button>
                                </Grid>
                                <Grid container item xs={12} justify="flex-end">
                                    <Grid item xs={6} sm={4}>
                                        <Button fullWidth color="primary" variant="contained" onClick={this.saveTrip}>Continue</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Layout>
            </ThemeProvider >
        )
    }
}

export default withStyles(styles)(CreateTrip)