import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText, ListItemAvatar } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core'
import { Avatar } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';

// import './styles/PlacesList.scss'

const useStyles = makeStyles((theme) => ({
    // FIX fontBold
    fontBold: {
        fontWeight: 900,
    },
    fontSmallIcon: {
        fontSize: '1rem',
    },
    deleteIcon: {
        backgroundColor: '#ff424e',
        borderRadius: '50%',
        border: '1px solid white',
        padding: theme.spacing(2),
        maxHeight: '100%',
        cursor: "pointer",
    },
    markerCard: {
        backgroundColor: 'white',
        padding: `0px ${theme.spacing(1)}px`,
        margin: `${theme.spacing(1)}px auto`,
        boxShadow: '0 2px 6px 0 rgba(0,0,0,.12)',
        borderRadius: theme.spacing(1)
    },
    scrollableFixed: {
        [theme.breakpoints.down('sm')]: {
            overflowY: "scroll",
            maxHeight: "45vh"
        }
    }
}));

function OneDay(props) {
    const classes = useStyles()

    function dateToString() {
        let date = new Date()
        return (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear())
    }

    return (
        <Grid container item xs={12} className={classes.markerCard}>
            {console.log("walla", props.place)}
            <Grid container item xs={10} justify="flex-start">
                <ListItemText
                    primary={<Typography className={classes.fontBold} variant="body2" component="h6">{props.place.placeName.split(',')[0]}</Typography>}
                    secondary={<Typography variant="body2" component="p" color="textSecondary">{dateToString(props.place.date)}</Typography>} />
            </Grid>
        </Grid>
    )
}

function DaysList(props) {
    const classes = useStyles()

    return (
        <div className={classes.scrollableFixed}>
            {props.markers.map((place, index) =>
                <OneDay day={index} place={place} />
            )}
        </div>
    );
}
export default DaysList