import React, { useEffect } from 'react'
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api'
import { formatRelative } from "date-fns";

import { makeStyles } from '@material-ui/core/styles';

const libraries = ['places'];
const center = {
    lat: 23.033863,
    lng: 72.585022
}

const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
}

const useStyles = makeStyles(theme => ({
}))

export default function GooglePlannerMap({ parentMarkers, selectedMarker, dimensions, mapLoaded }) {
    const classes = useStyles()
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.GATSBY_GOOGLE_KEY,
        libraries,
    });
    const [markers, setMarkers] = React.useState([]);
    const [selected, setSelected] = React.useState(null);

    //adding markers and using useCallback to avoid unnecessary callbacks
    // const addMarker = React.useCallback(({ lat, lng }, placeName) => {
    //     setMarkers((current) => [
    //         ...current,
    //         {
    //             lat: lat,
    //             lng: lng,
    //             placeName: placeName,
    //             time: new Date(),
    //         },
    //     ]);
    // }, []);

    const mapRef = React.useRef();
    const onMapLoad = React.useCallback((map) => {
        mapLoaded()
        mapRef.current = map;
    }, []);


    const panTo = React.useCallback(({ lat, lng }) => {
        console.log(lat, lng)
        mapRef.current.panTo({ lat, lng });
    }, []);

    useEffect(() => {
        if (selectedMarker.lat) {
            panTo({ lat: selectedMarker.lat, lng: selectedMarker.lng })
        }
    }, [selectedMarker])

    const renderMap = () => {
        return (
            <div>
                <GoogleMap
                    mapContainerStyle={dimensions}
                    zoom={6}
                    center={center}
                    options={mapOptions}
                    onLoad={onMapLoad}
                >
                    {
                        // console.log(parentMarkers)
                        parentMarkers.map((place, index) =>
                            <Marker
                                key={index}
                                position={{ lat: place.lat, lng: place.lng }}
                                onClick={() => {
                                    setSelected(place);
                                }} />)
                    }
                    {selectedMarker &&
                        <Marker
                            key="selected101"
                            position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                            onClick={() => {
                                setSelected(selectedMarker);
                            }} />
                    }

                    {selected ? (
                        <InfoWindow
                            position={{ lat: selected.lat + 0.25, lng: selected.lng }}
                            onCloseClick={() => {
                                setSelected(null);
                            }}
                        >
                            <div>
                                <h2>
                                    <span role="img" aria-label="bear">
                                    </span>{" "}
                                    {selected.placeName}
                                </h2>
                                <p>Marked @{formatRelative(selected.id, new Date())}</p>
                            </div>
                        </InfoWindow>
                    ) : null}
                </GoogleMap>
            </div>)
    }

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }

    return isLoaded ? renderMap() : <div>Loading</div>
}