import React, { useEffect } from 'react'
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";

import { TextField, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import theme from '../../utils/theme'


const useStyles = makeStyles((theme) => ({
    suggestionsList: {
        padding: "5px",
        position: "absolute",
        backgroundColor: "white",
        width: "67%",
        margin: "0",
        borderRadius: "10px",
        zIndex: "2"
    }
}))

export default function Search({ selectSearchOption, placeName }) {
    const classes = useStyles()
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
        },
        debounce: 300,
    });

    useEffect(() =>
        setValue(placeName, false)
        , [placeName])

    const handleInput = (e) => {
        // Update the keyword of the input element
        setValue(e.target.value);
    };

    const handleSelect = ({ description }) => () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
            .then(async (results) => {
                const { lat, lng } = await getLatLng(results[0])
                let placeName = results[0].formatted_address
                let place_id = results[0].place_id
                let data = { lat, lng, placeName, place_id }
                console.log("Coordinates: ", { lat, lng });
                console.log(results[0].formatted_address, results[0].place_id)
                selectSearchOption(data, "place")
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    };

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
                <ListItemText key={place_id} onClick={handleSelect(suggestion)} primary={<div><strong>{main_text}</strong> <small>{secondary_text}</small></div>} />
            );
        });

    return (
        <div>
            <TextField
                variant="outlined"
                label="Place"
                fullWidth={true}
                value={value}
                onChange={handleInput}
                disabled={!ready}
            />
            {/* We can use the "status" to decide whether we should display the dropdown or not */}
            {status === "OK" && <ul className={classes.suggestionsList}>{renderSuggestions()}</ul>}
        </div>
    );
};