import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia, CardContent, Button, Modal } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core'
import { Collapse } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import Search from '../map/search-location'

import Swiper from "../swiper"

// import './styles/tripCardList.scss'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'block',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        boxShadow: '0 2px 6px 0 rgba(0,0,0,.12)',
    },
    addButton: {
        display: 'block',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        boxShadow: '0 2px 6px 0 rgba(0,0,0,.12)',
        height: "68px",
    },
    listCardMedia: {
        height: '300px',
    },
    fontWeight7: {
        fontWeight: 700
    },
    fontSmallBold: {
        fontWeight: 'bold',
        fontSize: '16px',
    },
    learnMoreLink: {
        display: 'inline-flex',
        alignItems: 'center',
        textDecoration: 'none',
    },
    fontSizeSmall: {
        fontSize: '16px',
    },
    defaultInputHeight: {
        height: "45px",
    },
    divHidden: {
        display: 'none',
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    deleteIcon: {
        backgroundColor: '#ff424e',
        borderRadius: '50%',
        border: '1px solid white',
        padding: theme.spacing(2),
        maxHeight: '100%',
        cursor: "pointer",
    },
    datePicker: {
        marginTop: 0,
    }
}));

//Individual Trip Card
function TripCard(props) {
    const classes = useStyles()

    function handleInputChange(data, inputType) {
        data.id = props.marker.id
        props.updateInput(data, props['data-key'], inputType)
    }

    function deleteMarker() {
        props.deleteMarker(props['data-key'])
    }

    return (
        <Card className={classes.root}>
            {props.marker.photo_url ? <CardMedia
                alt="Trip Image"
                title="Trip Image"
                className={classes.listCardMedia}
            >
                <Swiper clipStyle="" sliderImage={props.marker.photo_url} />
            </CardMedia> : <CardContent><Typography variant="p" color="textSecondary">Add Image</Typography></CardContent>}

            <CardContent>
                <Grid container spacing={2}>
                    <Grid container item xs={12}>
                        {/* {props.index} */}
                        <Grid item xs={11}>
                            <Typography variant="h6" component="h6">{props.marker.placeName.split(",")[0]} </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Avatar className={classes.deleteIcon} onClick={deleteMarker}>
                                <DeleteIcon className={classes.fontSmallIcon} style={{ color: 'white' }} />
                            </Avatar>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} direction="row" spacing={1}>
                        <Grid item xs={8}>
                            {props.mapLoaded ?
                                <Search selectSearchOption={handleInputChange} placeName={props.marker.placeName} /> : <div>Loading</div>
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker className={classes.datePicker}
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Visit Date"
                                    value={props.marker.date}
                                    onChange={(date) => handleInputChange({ ...props.marker, date: date }, "date")}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Tell us more"
                            variant="outlined"
                            multiline={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            rows="5"
                            fullWidth={true}
                            value={props.marker['desc']}
                            onChange={(e) => handleInputChange({ ...props.marker, desc: e.target.value }, "desc")}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}


//Generate List of TripCard and export
function TripCardList(props) {
    const classes = useStyles();
    const [placeSelected, setPlaceSelected] = React.useState();
    const [daySelected, setDaySelected] = React.useState();


    function deleteMarker(markerId) {
        props.deleteMarker(markerId)
    }

    return (
        <Grid container spacing={2}>
            {props.markers.map((place, index) =>
                <Grid container item xs={12} direction="row">
                    <Grid item xs={12}>
                        <TripCard marker={place} key={place.id} index={index + 1} data-key={index} updateInput={props.updateInput} mapLoaded={props.mapLoaded} deleteMarker={deleteMarker} />
                    </Grid>
                </Grid>
            )}
        </Grid >
    )
}

export default TripCardList